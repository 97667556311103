import styled from "styled-components";

const IconWrapperSC = styled("div")`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const UseNotFoundIconStyle = () => ({
  IconWrapperSC,
});
