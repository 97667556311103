import AboutCompany from "components/AboutCompany/AboutCompany";
import React from "react";
import AppContainer from "../anyPage/AppContainer/AppContainer";
import FormFeedback from "./FormFeedback/FormFeedback";
import ProductList from "./ProductList/ProductList";
import { useBottomBlockStyle } from "./style";

// TODO ПЕРЕНЕСТИ ФОРМУ НАВЕРХ.

const BottomBlock = () => {
  return (
    <WrapperSC>
      <AppContainer maxWidth={1650}>
        <ContentWrapperSC>
          {/* <FormFeedback /> */}
          <ProductList />
        </ContentWrapperSC>
      </AppContainer>
    </WrapperSC>
  );
};

const { WrapperSC, ContentWrapperSC } = useBottomBlockStyle();

export default React.memo(BottomBlock);
