import styled from "styled-components";
import { InputSC } from "../../../../UI/InputUI/style";

const InputEmail = styled(InputSC)`
  margin-bottom: 27px;
`;

export const useEmailInputStyle = () => ({
  InputEmail,
});
