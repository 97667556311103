import styled, { keyframes } from "styled-components";

const rotateSpinner = keyframes`
0% {transform:rotate(0deg);}
40% {transform:rotate(160deg);}
80% {transform:rotate(360deg)}
100% {transform:rotate(360deg)}`;

const LoadIconWrapperSC = styled("div")`
  margin: 0 auto;
  animation-name: ${rotateSpinner};
  animation-duration: 6s;
  animation-iteration-count: infinite;
  margin-bottom: 10px;
`;

export const useLoadIconStyle = () => ({
  LoadIconWrapperSC,
});
