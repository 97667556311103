import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IProductCardsProps } from "../../types/types";

type InitialState = IProductCardsProps[];

const initialState: InitialState =
  JSON.parse(localStorage.getItem("data") || "{}")?.basket || [];

const slice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    setQuantity: (state, action) => {
      const existingIndex = state.findIndex(
        (item) => item.vendor_code === action.payload[1],
      );
      if (existingIndex !== -1) {
        state[existingIndex].quantity = action.payload[0];
      }
    },
    addProduct: (state, action) => {
      const existingIndex = state.findIndex(
        (item) => item.vendor_code === action.payload.vendor_code,
      );
      if (existingIndex !== -1) {
        state[existingIndex].quantity += 1;
      } else {
        state.push(action.payload);
      }
    },
    increaseQuantity: (state, action) => {
      const existingIndex = state.findIndex(
        (item) => item.vendor_code === action.payload,
      );
      if (existingIndex !== -1) {
        state[existingIndex].quantity += 1;
      }
    },
    decreaseQuantity: (state, action) => {
      const existingIndex = state.findIndex(
        (item) => item.vendor_code === action.payload,
      );
      if (existingIndex !== -1) {
        if (state[existingIndex].quantity > 1) {
          state[existingIndex].quantity -= 1;
        } else {
          state.splice(existingIndex, 1);
        }
      }
    },
    removeProduct: (state, action) => {
      const existingIndex = state.findIndex(
        (item) => item.vendor_code === action.payload,
      );
      if (existingIndex !== -1) {
        state.splice(existingIndex, 1);
      }
    },
    removeAllProducts: (state) => {
      localStorage.removeItem("data");
      return [];
    },
  },
});

export const {
  addProduct,
  increaseQuantity,
  decreaseQuantity,
  removeProduct,
  removeAllProducts,
  setQuantity,
} = slice.actions;

export default slice.reducer;

export const getBasket = (state: RootState) => state.basket;
