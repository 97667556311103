//@ts-nocheck
import React, { ComponentType } from "react";
import TitleUI from "../../../UI/TitleUI/TitleUI";
import { useProductListStyle } from "./style";
import { useProductList } from "./useProductList";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid as Grid } from "react-window";
import Row from "./Row/Row";
import { ButtonUI } from "../../../UI/ButtonUI/style";
import ProductCard from "./ProductCard/ProductCard";
import LoadIcon from "components/LoadIcon/LoadIcon";

const ProductList = () => {
  const {
    handleClickMobile,
    marginBottom,
    rowHeight,
    isEndCards,
    loading,
    difference,
    limit,
    isTable,
    cards,
    loader,
  } = useProductList();

  // console.log(cards);

  // ! у продукуции нет id
  const CardsElement = cards.map((el, i) => (
    <ProductCard
      key={i}
      title={el.title}
      description={el.description}
      vendor_code={el.vendor_code}
      price={el.price}
      image={el.image}
      style={{}}
    />
  ));

  return (
    <ListWrapperSC>
      <TitleWrapperSC>
        {/* <TitleUI
          style={{
            fontWeight: 600,
            fontSize: "72px",
            lineHeight: "104px",
            margin: "0 auto 68px",
            maxWidth: "none",
          }}
          textAlign="center"
          text="Каталог продукции"
        /> */}
        <TitleSC id="Catalog">Каталог продукции</TitleSC>
      </TitleWrapperSC>
      {/* {mapsa} */}
      <GridContainer>{CardsElement}</GridContainer>
      {/* <div>{mapsa}</div> */}
      <ButtonUI
        style={{ margin: "20px auto 40px" }}
        onClick={handleClickMobile}>
        Показать ещё
      </ButtonUI>
    </ListWrapperSC>
  );

  return (
    <ListWrapperSC>
      <>
        <TitleWrapperSC>
          <TitleUI
            style={{
              fontWeight: 600,
              fontSize: "72px",
              lineHeight: "104px",
              margin: "0 auto",
              maxWidth: "none",
            }}
            textAlign="center"
            text="Каталог продукции"
          />
        </TitleWrapperSC>
        {!loading && (
          <AutoSizer
            style={{
              /* height: `${
                (limit / 2) * rowHeight + (marginBottom - difference) * limit
              }px`, */
              height: "100%",
              position: "sticky",
              overflow: "visible",
            }}>
            {({ height, width }) => {
              return (
                <Grid
                  style={{ overflow: "visible" }}
                  itemData={cards}
                  height={
                    (limit / 2) * rowHeight +
                    (marginBottom - difference) * limit
                  }
                  width={width}
                  rowHeight={rowHeight + marginBottom}
                  columnCount={3}
                  columnWidth={width / 3}
                  rowCount={limit > 1000 ? 1000 / 3 : limit / 3}>
                  {Row}
                </Grid>
              );
            }}
          </AutoSizer>
        )}
        {loading && <LoadIcon />}
        {!isTable && <div ref={loader} />}
        <CardListSC></CardListSC>
        {isTable && (
          <ButtonUI onClick={handleClickMobile}>Показать ещё</ButtonUI>
        )}
        <CardListSC></CardListSC>
      </>
    </ListWrapperSC>
  );
};

const { CardListSC, ListWrapperSC, TitleWrapperSC, GridContainer, TitleSC } =
  useProductListStyle();

export default React.memo(ProductList);
