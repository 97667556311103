import AboutCompany from "components/AboutCompany/AboutCompany";
import FormFeedback from "components/BottomBlock/FormFeedback/FormFeedback";
import BottomBlock from "components/BottomBlock/BottomBlock";
import React from "react";
import ArrowTop from "./ArrowTop/ArrowTop";
import { useMainStyle } from "./style";
import { useMain } from "./useMain";

const Main = () => {
  const { isTable } = useMain();
  // TODO СТРЕЛКИ ВВЕВРХ НЕТУ В ДИЗАЙНЕ?
  return (
    <WrapperSC>
      {/* {isTable && <AboutCompany />} */}
      <Background>
        <AboutCompany />
      </Background>
      <div>
        <FormFeedback />
        <BottomBlock />
        <ArrowTop />
      </div>
    </WrapperSC>
  );
};

const { WrapperSC, Background } = useMainStyle();

export default React.memo(Main);
