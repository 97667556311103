import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

export const TitleSC = styled("h2")<{ textAlign: string }>`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  /* font-size: 72px;
  line-height: 104px; */
  /* color: #f6c914; */
  max-width: 465px;
  color: #000000;
  text-transform: uppercase;
  text-align: ${({ textAlign }) => textAlign};
  ${mediaCSS(600)} {
    max-width: 280px;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    margin: 0 auto 30px;
  }
`;

export const useTitleStyle = () => ({
  TitleSC,
});
