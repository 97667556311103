import React, { useState } from "react";
import ReactDOM from "react-dom";
import { IProductCardsProps } from "../../../../types/types";
import FullVisible from "./FullVisible/FullVisible";
import NotFoundIcon from "./NotFoundIcon/NotFoundIcon";
import { useProductCardStyle } from "./style";
import { useProductCard } from "./useProductCard";
import { useDispatch } from "react-redux";
import { addProduct } from "../../../../store/features/basketSlice";
import ProductForm from "./ProductForm/ProductForm";
import productForm from "./ProductForm/ProductForm";

type ModalState = "requisites" | "basket" | "productForm" | "";

const ProductCard = ({
  price,
  title,
  description,
  image,
  vendor_code,
  style,
}: IProductCardsProps) => {
  const { isTable, isVisibleFull, handleClickOut, handleShowFull } =
    useProductCard();

  const dispatch = useDispatch();

  const addToBasket = () => {
    dispatch(
      addProduct({
        price,
        title,
        vendor_code,
        image,
        quantity: 1,
      }),
    );
  };

  const [productForm, setProductForm] = useState<ModalState>("");
  const handleProductForm = (data: ModalState) => {
    return () => {
      setProductForm(data);
    };
  };

  return (
    <>
      <CardWrapperSC style={style}>
        <CardClickableSC onClick={handleShowFull}>
          {image ? (
            <ImgSC src={image} />
          ) : (
            <PlaceholderWrapperSC>
              <NotFoundIcon />
            </PlaceholderWrapperSC>
          )}
          <RightWrapperSC>
            <TopWrapperSC>
              <PriceSC>{price === "По запросу" ? price : `${price} ₽`}</PriceSC>
              <NumberSC>{vendor_code}</NumberSC>
              <PartNameSC>{title}</PartNameSC>
            </TopWrapperSC>
            <DescriptionSC>{description}</DescriptionSC>

            {isTable && (
              <RowContainer>
                <NumberSC>{vendor_code}</NumberSC>
                <PriceSC>
                  {price === "По запросу" ? price : `${price} ₽`}
                </PriceSC>
              </RowContainer>
            )}
          </RightWrapperSC>
        </CardClickableSC>
        <div
          style={{
            width: "100%",
            padding: "8px",
            display: "flex",
            justifyContent: "center",
          }}>
          {price === "По запросу" ? (
            <ButtonCardSC onClick={() => setProductForm("productForm")}>
              Уточнить цену
            </ButtonCardSC>
          ) : (
            <ButtonCardSC onClick={() => addToBasket()}>Добавить</ButtonCardSC>
          )}
        </div>

        {isVisibleFull && (
          <>
            {isTable ? (
              ReactDOM.createPortal(
                <FullVisible
                  handleClickOut={handleClickOut}
                  title={title}
                  vendorCode={vendor_code}
                  price={price}
                  src={image}
                  description={description}
                />,
                document.body,
              )
            ) : (
              <FullVisible
                handleClickOut={handleClickOut}
                title={title}
                vendorCode={vendor_code}
                description={description}
                src={image}
                price={price}
              />
            )}
          </>
        )}
      </CardWrapperSC>
      <ProductForm
        isOpen={productForm === "productForm"}
        handleClose={handleProductForm}
        className="productForm"
      />
    </>
  );
};

const {
  ImgSC,
  PriceSC,
  NumberSC,
  PartNameSC,
  TopWrapperSC,
  DescriptionSC,
  CardWrapperSC,
  RightWrapperSC,
  PlaceholderWrapperSC,
  RowContainer,
  ButtonCardSC,
  CardClickableSC,
} = useProductCardStyle();

export default React.memo(ProductCard);
