import styled from "styled-components";
import { mediaCSS } from "../../../../theme/CSSPattern";
import { ButtonUI } from "../../../../UI/ButtonUI/style";

const CardWrapperSC = styled("div")`
  display: flex;
  gap: 16px;
  height: 100px;
  @media (max-width: 320px) {
    gap: 10px;
  }
`;

const TopWrapperSC = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  width: 0;
  div {
    display: inline-block;
    width: 100%;
  }
`;

const RightWrapperSC = styled("div")`
  flex: 1;
  display: inline-flex;
  gap: 16px;
  justify-content: space-between;
  @media (max-width: 320px) {
    gap: 10px;
  }
`;

const PriceSC = styled("h4")`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: white;
  font-family: sans-serif;
`;

const NumberSC = styled("p")`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: white;
`;

const ImgSC = styled("img")`
  object-fit: cover;
  width: 100px;
  height: 100px;
`;
const PlaceholderWrapperSC = styled("div")`
  position: relative;
  background: #c9c3b5;
  max-width: 47%;
  width: 100%;
  height: 100%;
`;

const PartNameSC = styled("h3")`
  max-width: 100%;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionWrapperSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const CountContainerSC = styled.div`
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
`;

const SplitButtonSC = styled.button`
  font-size: 16px;
  font-weight: 600;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 6px;
  background: white;
  text-align: center;
`;

const CountSC = styled.input`
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  width: 24px;

  border: none;

  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  &:focus {
    outline: none;
  }
`;

const ButtonSC = styled.button`
  width: 20px;
  height: 20px;
  background: transparent;
  cursor: pointer;
`;

export const useBasketCardStyles = () => ({
  ImgSC,
  PriceSC,
  NumberSC,
  PartNameSC,
  TopWrapperSC,
  CardWrapperSC,
  RightWrapperSC,
  PlaceholderWrapperSC,
  ActionWrapperSC,
  ButtonSC,
  CountContainerSC,
  SplitButtonSC,
  CountSC,
});
