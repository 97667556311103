import React from "react";
import { Controller } from "react-hook-form";
import { useFullNameStyle } from "./style";
import { useFullNameInput } from "./useFullNameInput";
import InputUI from "../../../../../../UI/InputUI/InputUI";

const TextArea = () => {
  const { control } = useFullNameInput();
  return (
    <Controller
      name="name"
      control={control}
      rules={{ required: true }}
      defaultValue={""}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <InputUI
            value={value}
            onChange={onChange}
            error={!!error}
            styleComponents={{ InputStyle: InputFullName }}
            inputProps={{ placeholder: "Ф.И.О" }}
          />
        );
      }}
    />
  );
};

const { InputFullName } = useFullNameStyle();

export default React.memo(TextArea);
