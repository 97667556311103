import { useTablet } from "hooks/useMediaQuery";
import { useEffect, useRef, useState } from "react";

export const useFullVisible = (handleClickOut: () => void) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const isTable = useTablet();
  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isOpen]);
  const handleClick = (e) => {
    if (wrapperRef.current && isOpen) {
      handleClickOut();
      setIsOpen(false);
    }
    setIsOpen(true);
  };
  return { wrapperRef, isOpen, isTable };
};
