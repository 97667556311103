import "./styles/globals.css";
import React from "react";
import Header from "./components/Header/Header";
import Theme from "./theme/Theme";
import { useTablet } from "hooks/useMediaQuery";
import Main from "components/Main/Main";
import { Provider } from "react-redux";
import { store } from "./store/store";

function App() {
  const isTable = useTablet();
  return (
    <Provider store={store}>
      <Theme>
        <Header />
        <Main />
      </Theme>
    </Provider>
  );
}

export default App;
