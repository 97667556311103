import styled from "styled-components";
// import { TitleSC } from "UI/TitleUI/style";
import { mediaCSS } from "../../../theme/CSSPattern";
const TitleWrapperSC = styled("div")`
  margin-bottom: 35px;
  ${mediaCSS(996)} {
    margin-bottom: 29px;
  }
`;

const CardListSC = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ListWrapperSC = styled("section")`
  display: flex;
  flex-direction: column;
  /* max-width: 1200px; */
  /* width: 75%; */
  margin: 0 auto;
  /* margin-left: 85px; */
  ${mediaCSS(1200)} {
    margin-left: 35px;
  }
  ${mediaCSS(1080)} {
    margin-left: 20px;
  }
  ${mediaCSS(996)} {
    margin-left: 0px;
    width: 100%;
  }
`;

const GridContainer = styled("section")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: calc(100% - 48px * 3); */
  gap: calc(0.4rem + 1vw);
  width: 100%;
  ${mediaCSS(837)} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaCSS(625)} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const TitleSC = styled("h3")`
  font-weight: 800;
  /* font-size: calc(35px + 1.24vw); */
  font-size: calc(3.3rem + 1.24vw);
  text-align: center;
  margin: 0 auto 68px;
  ${mediaCSS(827)} {
    /* font-size: 35px; */
    font-size: calc(2rem + 1.24vw);

    font-weight: 600;
    /* line-height: 45px; */
  }
`;

export const useProductListStyle = () => ({
  CardListSC,
  ListWrapperSC,
  TitleWrapperSC,
  GridContainer,
  TitleSC,
});
