import styled from "styled-components";
import { mediaCSS } from "theme/CSSPattern";

export const ContainerAppSC = styled("div")<{ maxWidth: number }>`
  max-width: ${({ maxWidth }) => maxWidth}px;
  width: 100%;
  /* padding: 0 64px; */
  margin: 0 auto;
  /* ${mediaCSS(360)} {
    padding: 0 20px;
    margin-left: 0px;
    width: 100%;
  } */
`;
