import styled from "styled-components";

const WrapperSC = styled("div")`
  display: none;

  @media (min-width: 827px) {
    cursor: pointer;
    position: fixed;
    bottom: 35px;
    right: 35px;
    width: 56px;
    height: 56px;
    /* width: 41px;
    height: 113px; */
    display: flex;
  }
`;

/* const TextSC = styled("p")`
  position: absolute;
  top: 72px;
  width: 68px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  transform: rotate(90deg);
`; */

export const useArrowTopStyle = () => ({
  // TextSC,
  WrapperSC,
});
