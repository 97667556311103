import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

const BGSC = styled("section")`
  margin: 0;

  display: flex;
  bottom: 137px;
  left: 146px;
  align-items: end;
  margin: 0 auto;
  width: 75%;
  /* margin-bottom: 64px; */

  ${mediaCSS(996)} {
    align-items: end;
  }
  ${mediaCSS(550)} {
    width: 92%;
  }
`;

const TitleSC = styled("h1")`
  /* font-weight: 400; */
  font-weight: 600;

  font-size: 108px;

  text-align: left;
  white-space: nowrap;

  color: #000000;

  width: 694px;
  ${mediaCSS(996)} {
    text-align: left;
    // margin-bottom: 11px;
    max-width: 285px;

    font-weight: 600;
    font-size: 45px;

    color: #000000;
  }
`;

const DescrSC = styled("p")`
  color: #000000;
  font-weight: 600;
  // max-width: 1444px;
  // margin: 0 0 0 auto;

  font-style: normal;

  font-size: 18px;
  margin-bottom: 16px;
  max-width: 515px;
  @media (max-width: 1400px) {
    max-width: 372px;
  }
  @media (max-width: 1054px) {
    max-width: 374px;
  }
  /* @media (max-width: 1032px) {
    max-width: 395px;
  } */
  @media (max-width: 612px) {
    font-size: 10px;
    max-width: 314px;
  }
  @media (max-width: 604px) {
    /* max-width: 213px; */
    max-width: 180px;
  }
`;

const WrapperSC = styled("section")``;

const WrappWidth = styled("div")`
  /* width: 75%; */
  width: 89%;
  margin: 0 auto;

  @media (max-width: 1032px) {
    width: 90%;
  }
`;

export const useAboutCompanyStyle = () => {
  return { BGSC, TitleSC, DescrSC, WrapperSC, WrappWidth };
};
