import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

export const ButtonUI = styled("button")`
  cursor: pointer;
  /* background: ${({ theme }) => theme.buttonColor}; */
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  width: 376px;
  /* margin-bottom: 48px; */
  margin: 0 auto;
  height: 64px;
  border-radius: 48px;
  background: #474748;
  backdrop-filter: blur(9.5px);
  ${mediaCSS(812)} {
    height: 40px;
    font-size: 15px;
    line-height: 20px;
    max-width: 360px;
    width: calc(100% - 42px * 2);
  }
`;
