import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useEmailJS } from "../../../hooks/useEmailJS";
import { useMobile, useTablet } from "../../../hooks/useMediaQuery";

export const useFormFeedback = () => {
  const method = useForm();
  const isMobile = useMobile();
  const isTable = useTablet();
  const { control, handleSubmit } = method;
  const [isError, setError] = useState<boolean | undefined | void>(false);
  const { onFetchMail } = useEmailJS();
  const onSubmit = async (data: FieldValues) => {
    setError(false);
    const mailData = {
      name: data.name,
      vacancy: null,
      email: data.email,
      phone: data.phone,
      aboutMySelf: data.aboutUs,
    };
    const error = await onFetchMail(mailData);
    setError(error);
  };
  return {
    method,
    isError,
    isMobile,
    isTable,
    control,
    onSubmit,
    handleSubmit,
  };
};
