import {
  Action,
  createListenerMiddleware,
  isAnyOf,
  ThunkAction,
} from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import basket, {
  addProduct,
  decreaseQuantity,
  increaseQuantity,
  removeAllProducts,
  removeProduct,
} from "./features/basketSlice";

const localStorageMiddleware = createListenerMiddleware();

localStorageMiddleware.startListening({
  matcher: isAnyOf(
    addProduct,
    increaseQuantity,
    decreaseQuantity,
    removeProduct,
    removeAllProducts,
  ),
  effect: (action, listenerApi) =>
    localStorage.setItem("data", JSON.stringify(listenerApi.getState())),
});

export const store = configureStore({
  reducer: {
    basket,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().prepend(localStorageMiddleware.middleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;
