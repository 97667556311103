import React, { ReactElement, ReactNode } from "react";
import { ContainerAppSC } from "./style";

interface IAppContainerProps {
  maxWidth?: number;
  children: ReactElement;
}

const AppContainer = ({ maxWidth = 1665, children }: IAppContainerProps) => {
  return (
    <>
      <ContainerAppSC maxWidth={maxWidth}>{children}</ContainerAppSC>
    </>
  );
};
export default React.memo(AppContainer);
