import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

export const useEmailJS = () => {
  const serviceId = "service_kn86hmn";
  const templateId = "template_ll27ezk";
  const publickKey = "pupEkh1CP-9ICcR3L";
  const form = useRef();
  const onFetchMail = async (formValue: any) => {
    return await emailjs
      .send(serviceId, templateId, formValue, publickKey)
      .then(
        (result) => {},
        (error) => {
          return error;
        },
      );
  };
  return { onFetchMail };
};
