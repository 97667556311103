import styled from "styled-components";
import { mediaCSS } from "theme/CSSPattern";

/* const WrapperSC = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 826px;
  width: 90%;
  z-index: 1000;
  padding: 30px;
  box-sizing: border-box;
  ${mediaCSS(1280)} {
    max-width: 600px;
  }
  ${mediaCSS(600)} {
    max-width: 283px;
    flex-direction: column;
  }
`; */

/* const WrapperSC = styled("div")`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 15px;
  max-width: 826px;
  width: 100%;
  height: 272px;
  overflow: hidden;
  margin: 0 auto;
  padding: 16px;
  box-sizing: border-box;
  text-overflow: ellipsis;

  ${mediaCSS(1400)} {
    max-width: 600px;
  }

  ${mediaCSS(996)} {
    max-width: 600px;
  }

  ${mediaCSS(600)} {
    max-width: 283px;
  }
`; */

const WrapperSC = styled("div")`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 15px;
  max-width: 826px;
  width: 100%;
  height: 272px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 auto;
  padding: 16px;
  box-sizing: border-box;

  position: relative;

  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 transparent;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 3px;
  }

  /*  ${mediaCSS(1400)} {
    max-width: 600px;
  } */

  ${mediaCSS(812)} {
    flex-direction: column;
    margin: 0 35px 0;
    /* max-width: 600px; */
  }

  /* ${mediaCSS(600)} {
    max-width: 283px;
  } */
`;

const ImgSC = styled("img")`
  max-height: 407px;
  height: 100%;
  max-width: 259px;
  /* width: 100%; */
  object-fit: cover;
  ${mediaCSS(996)} {
    max-width: 100%;
    max-height: 115px;
  }
`;

const TopWrapperSC = styled("div")`
  /*   display: flex;
  flex-direction: column;

  align-items: center; */
`;

const VendorCodeSC = styled("h4")`
  /* font-size: 18px; */
  /* line-height: 25px; */
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #8c8c8c;
  ${mediaCSS(600)} {
    font-weight: 600;
    font-size: calc(14px * 100%);
    line-height: 19px;
  }
`;

const PriceSC = styled("h3")`
  font-weight: 400;
  font-size: calc(24px * 100%);
  line-height: 30px;
  color: #000000;
  ${mediaCSS(600)} {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
`;

const TitleSC = styled("h2")`
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 4px;
  ${mediaCSS(600)} {
    font-weight: 700;
    font-size: calc(16px * 100%);
    line-height: 22px;
  }
`;

const RightWrapperSC = styled("div")`
  /* padding: 15px; */
  padding: 0 15px;
  /* padding: 11px 49px 29px 18px; */
`;

const DescriptionSC = styled("p")`
  padding-bottom: 16px;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #000000;
  ${mediaCSS(600)} {
    font-weight: 600;
    font-size: calc(14px * 100%);
    /* line-height: 19px; */
  }
`;

const ExternalWrapperSC = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ArticleSC = styled("article")`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: visibility 0.6s, opacity 0.6s;
  visibility: hidden;
  z-index: 10;
`;

const SvgContainer = styled("div")`
  position: absolute;
  right: 10px;
`;

const ContainerRow = styled("div")`
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const useFullVisibleStyle = () => ({
  WrapperSC,
  ImgSC,
  PriceSC,
  TitleSC,
  VendorCodeSC,
  TopWrapperSC,
  DescriptionSC,
  RightWrapperSC,
  ExternalWrapperSC,
  SvgContainer,
  ArticleSC,
  ContainerRow,
});
