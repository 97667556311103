import styled from "styled-components";
import { InputSC } from "../../../../UI/InputUI/style";

const InputTheme = styled(InputSC)<{ isError: boolean }>`
  margin-bottom: 53px;
`;

export const useThemeInputStyle = () => ({
  InputTheme,
});
