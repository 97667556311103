import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMobile, useTablet } from "../../../../hooks/useMediaQuery";
import { useEmailJS } from "../../../../hooks/useEmailJS";

export const useBasketFormFeedback = () => {
  const method = useForm();
  const isMobile = useMobile();
  const isTable = useTablet();
  const { control, handleSubmit } = method;
  const [isError, setError] = useState<boolean | undefined | void>(false);
  const [isSuccess, setSucces] = useState<boolean | undefined | void>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { onFetchMail } = useEmailJS();

  const onSubmit = async (data: FieldValues) => {
    setError(false);
    setIsLoading(true);

    const mailData = {
      name: data.name,
      vacancy: null,
      email: data.email,
      phone: data.phone,
      aboutMySelf: data.aboutUs,
    };
    const error = await onFetchMail(mailData);
    setIsLoading(false);
    if (error === undefined) {
      setSucces(true);
    }
    setError(error);
  };
  return {
    isLoading,
    isSuccess,
    method,
    isError,
    isMobile,
    isTable,
    control,
    onSubmit,
    handleSubmit,
  };
};
