//@ts-nocheck
// TODO ПОПРАВИТЬ ТИПИЗАЦИЮ! НЕ ДАЁТ ИСПОЛЬЗОВАТЬ КАРТИНКУ
import styled, { css } from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

const CSSSticky = css`
  position: sticky;
  top: 0;
`;

const HeaderContainerSC = styled("div")`
  //   margin: 28px auto 0 auto;
  top: 0;
  ${CSSSticky}
  display: flex;
  width: 100%;
  // background-color: ${({ theme }) => theme.violetColor};
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(9.5px);
  position: fixed;
  width: 100%;
  padding: 0 64px;
  z-index: 9999;
  height: 65px;
  justify-content: space-between;

  ${mediaCSS(1200)} {
    padding: 0 26px;
  }
`;

const HeaderSC = styled("header")`
  ${CSSSticky}
  display: flex;
  top: 0;
  z-index: 9999;
`;

const WrapperSC = styled("div")`
  display: flex;
  /* justify-content: right; */
  justify-content: space-between;
  align-items: center;
  height: 100%;
  ${mediaCSS(996)} {
    justify-content: center;
  }
  ${mediaCSS(380)} {
    padding-left: 50px;
  }
`;

const NavSC = styled("nav")`
  /* max-width: 188px;
  width: 100%; */
  max-width: 700px;
  /* width: 35px; */
`;

const UlNavSC = styled("ul")`
  display: flex;
  /* width: max-content; */
  /* gap: 72px; */
  color: #fff;
  gap: 16px;
  justify-content: space-between;
  ${mediaCSS(996)} {
    flex-direction: column;
  }
`;

const LiSC = styled("li")`
  position: relative;
  cursor: pointer;
  font-weight: 700;
  /* font-size: 18px; */
  font-size: calc(18px * 100%);
  /* line-height: 25px; */
  /* color: #f6c914; */
  color: #ffffff;
  ${mediaCSS(996)} {
    margin-bottom: 15px;
    color: #000000;
  }

  a {
    color: #fff;
  }
`;

const WrapperTitleSC = styled("div")`
  display: flex;
  align-items: center;
`;

const ButtonMobileSC = styled("div")<{ isOpen: boolean }>`
  transition: all 0.5s;

  cursor: pointer;
  width: 36px;
  height: 36px;
  left: 12px;
  top: 12px;
  border-radius: 5px;
  position: fixed;
  background: url("burger.svg") no-repeat center center;
  /* display: ${({ isOpen }) => (isOpen ? "none" : "block")}; */
  z-index: 9999;
  & > span {
    top: 15px;
  }
  & > span,
  span::before,
  span::after {
    left: 50%;
    transform: translateX(-50%);
    display: block;
    position: relative;
    width: 16px;
    height: 2px;
    background-color: ${({ isOpen }) => (isOpen ? "#ffffff" : "#4C3348")};
  }
  & > span::before {
    content: "";
    top: -6px;
  }
  & > span::after {
    content: "";
    top: 5px;
  }
`;

const SidebarSC = styled("div")<{ isOpen: boolean }>`
  transition: all 0.5s;
  position: fixed;
  /* width: 221px; */
  width: 295px;
  /* height: 166px; */
  height: 100vh;
  /* background: #ffffff; */
  background: #000;
  /* background: rgba(0, 0, 0, 0.7); */

  color: #fff;
  border-radius: 0px 0px 8px 0px;
  left: ${({ isOpen }) => (isOpen ? 0 : -400)}px;
  top: 0;
  padding: 80px 0 10px 22px;
`;
const MenuSC = styled("h3")`
  font-weight: 600;
  font-size: calc(24px * 100%);
  /* line-height: 29px; */
  text-transform: uppercase;
  margin-left: 36px;
  margin-bottom: 31px;
`;

const ContactOverlaySC = styled("div")`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ContactWrapperSC = styled("div")<{ isShow: boolean }>`
  transition: all 0.5s;
  position: fixed;
  /* top: ${({ isShow }) => (isShow ? "72px" : "-300px")}; */
  left: ${({ isShow }) => (isShow ? "65px" : "-500px")};
  right: 300px;
  top: 50%;
  /* right: ; */
  /* max-width: 826px; */
  max-width: 315px;
  min-width: 230px;
  /* width: 40%; */
  background: #ffffff;
  /* border: 3px solid #000000; */
  /* border-radius: 15px; */
  /* padding: 16px 19px 24px 29px; */
  padding: 20px 15px;
  ${mediaCSS(600)} {
    padding: 10px 5px;
  }
`;
const TextSC = styled("p")`
  /* font-size: 16px; */
  display: flex;
  flex-direction: column;
  /* width: 315px; */
  /* height: 90px; */
  height: 66px;
  /* gap: 10px; */
  color: #6f6f6f;
  text-align: center;
  font-size: calc(15px * 100%);
  font-weight: 600;
  /* line-height: 20px; */
  /* line-height: 22px; */
`;

const ContainerContantSC = styled("div")`
  display: flex;
  justify-content: space-between;
  /* width: 512px; */
  gap: 16px;
  color: #fff;
`;

const SideBarOverlay = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(9.5px);
  z-index: 9998;
`;

const SpanCountSC = styled("span")`
  display: flex;
  justify-content: center;
  align-items: center;

  color: black;
  background: white;
  border-radius: 50%;

  position: absolute;
  right: -12px;
  bottom: -9px;

  height: 18px;
  width: 18px;
  font-size: 12px;
`;

export const useHeaderStyle = () => ({
  LiSC,
  NavSC,
  TextSC,
  MenuSC,
  UlNavSC,
  HeaderSC,
  WrapperSC,
  SidebarSC,
  ContactWrapperSC,
  ContactOverlaySC,
  WrapperTitleSC,
  ButtonMobileSC,
  HeaderContainerSC,
  ContainerContantSC,
  SideBarOverlay,
  SpanCountSC,
});
