import React from "react";
import { useAboutCompanyStyle } from "./style";

const AboutCompany = () => {
  return (
    // <ContainerSC>
    // <WrapperSC></WrapperSC>
    <WrapperSC>
      <WrappWidth>
        <DescrSC>
          У нас вы можете найти запчасти для любой спецтехники. Широкий
          ассортимент и приятные цены ждут вас! Мы гарантируем, что
          представленные товары прослужат долго. Покупайте у нас, ведь все наши
          запчасти оригинальные.
        </DescrSC>
        <TitleSC>О компании</TitleSC>
      </WrappWidth>
    </WrapperSC>
  );

  return (
    // <div>
    <BGSC>
      <WrapperSC>
        <DescrSC>
          У нас вы можете найти запчасти для любой спецтехники. Широкий
          ассортимент и приятные цены ждут вас! Мы гарантируем, что
          представленные товары прослужат долго. Покупайте у нас, ведь все наши
          запчасти оригинальные.
        </DescrSC>
        <TitleSC>О компании</TitleSC>
      </WrapperSC>
    </BGSC>
    // </div>\
  );
};

const { BGSC, TitleSC, DescrSC, WrapperSC, WrappWidth } =
  useAboutCompanyStyle();

export default React.memo(AboutCompany);
