import styled from "styled-components";
import { InputSC } from "UI/InputUI/style";

const InputEmail = styled(InputSC)`
  padding: 24px;
`;

export const useEmailInputStyle = () => ({
  InputEmail,
});
