import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useProductFormFeedback } from "./useProductFormFeedback";
import TitleUI from "../../../../../UI/TitleUI/TitleUI";
import FullNameInput from "./FullNameInput/FullNameInput";
import EmailInput from "./EmailInput/EmailInput";
import { ButtonUI } from "../../../../../UI/ButtonUI/style";
import { useProductFormStyle } from "./style";
import { createPortal } from "react-dom";

type ModalState = "requisites" | "basket" | "productForm" | "";

interface IModalUIProps {
  isOpen: boolean;
  handleClose: (arg: ModalState) => () => void;
  className?: string;
}

const ProductForm = ({
  isOpen,
  className = "",
  handleClose,
}: IModalUIProps) => {
  const {
    method,
    isError,
    isSuccess,
    isLoading,
    isMobile,
    isTable,
    control,
    onSubmit,
    handleSubmit,
  } = useProductFormFeedback();

  const [state, setState] = useState(false);
  const [text, setText] = useState("Отправить");

  const onKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose("")();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setState(false);
    }
    if (isSuccess) {
      handleClose("")();
    }
    if (isOpen) {
      document.body.style.overflowY = "hidden";
      window.addEventListener("keydown", onKey);
    } else {
      document.body.style.overflowY = "scroll";
      window.removeEventListener("keydown", onKey);
    }
    return () => {
      window.removeEventListener("keydown", onKey);
    };
  }, [isOpen, isSuccess]);

  const handleCloseForm = () => {
    setTimeout(() => {
      setText("Успешно!");
      setTimeout(() => {
        location.reload();
      }, 1200);
    }, 1000);
  };

  return isOpen ? (
    <>
      <ModalContainerSC>
        <MainContainerSC className={className}>
          <FormProvider {...method}>
            <FormSC>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}>
                <ReplyIconSC
                  onClick={() => handleClose("")()}
                  src="ReplyIcon.svg"
                />
              </div>
              <FormTitleWrapperSC>
                <TitleUI
                  text="Отправьте заявку и мы с вами свяжемся."
                  textAlign={isMobile ? "center" : "center"}
                  styleComponents={{ TitleStyle: FormTitleSC }}
                  style={{ textTransform: "none" }}
                />
              </FormTitleWrapperSC>
              <FormWrapperSC>
                <div>
                  <FullNameInput />
                  <EmailInput />
                </div>

                <ButtonUI onClick={handleCloseForm}>{text}</ButtonUI>
                {isError && (
                  <ErrorMessageSC>
                    Что-то пошло не так, попробуйте снова...
                  </ErrorMessageSC>
                )}
              </FormWrapperSC>
            </FormSC>
          </FormProvider>
        </MainContainerSC>
      </ModalContainerSC>
      {createPortal(
        <ModalBackdoorSC onClick={handleClose("")} />,
        document.querySelector("#root") as HTMLElement,
      )}
    </>
  ) : null;
};

const {
  FormSC,
  ButtonSC,
  FormTitleSC,
  RequisitesSC,
  DescriptionSC,
  ErrorMessageSC,
  RequisitesTitleSC,
  FormTitleWrapperSC,
  FormWrapperSC,
  RequisitesTitleWrapperSC,
  ReplyIconSC,
  ModalContainerSC,
  MainContainerSC,
  ModalBackdoorSC,
} = useProductFormStyle();

export default React.memo(ProductForm);
