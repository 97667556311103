import React, { useState } from "react";
import AppContainer from "../anyPage/AppContainer/AppContainer";
import { useHeaderStyle } from "./style";
import { useHeader } from "./useHeader";
import Requisites from "./Requisites/Requisites";
import Basket from "./Basket/Basket";
import { useSelector } from "react-redux";
import { getBasket } from "../../store/features/basketSlice";

const Header = () => {
  const basketProducts = useSelector(getBasket);

  const totalCount = () => {
    let allProductCount = 0;
    for (let i in basketProducts) {
      allProductCount += basketProducts[i].quantity;
    }
    return allProductCount;
  };

  const {
    isTable,
    ScrollTo,
    menuButton,
    menuMobile,
    contactRef,
    contactButton,
    handleContact,
    IsOpenSidebar,
    isShowContact,
    scrollToCatalog,
    scrollToForm,
    requisites,
    setRequisites,
    handleRequisites,
    basket,
    setBasket,
    handleBasket,
  } = useHeader();
  return (
    <HeaderSC>
      <HeaderContainerSC>
        <AppContainer maxWidth={1696}>
          <WrapperSC>
            {isTable && (
              <>
                <ButtonMobileSC
                  isOpen={IsOpenSidebar}
                  // onClick={handleClickMenu}
                  ref={menuButton}></ButtonMobileSC>
                {IsOpenSidebar && (
                  <SideBarOverlay>
                    <SidebarSC isOpen={IsOpenSidebar} ref={menuMobile}>
                      {/* <MenuSC>Меню</MenuSC> */}
                      <NavSC>
                        <UlNavSC>
                          <LiSC ref={contactButton} onClick={ScrollTo(0)}>
                            <a>О компании</a>
                          </LiSC>
                          <LiSC onClick={scrollToForm} ref={contactButton}>
                            <a>Обратная связь</a>
                          </LiSC>
                          <LiSC onClick={() => setRequisites("requisites")}>
                            <a>Покупателям</a>
                          </LiSC>
                          <LiSC onClick={scrollToCatalog}>
                            <a href="#Catalog">Каталог</a>
                          </LiSC>
                          <LiSC onClick={handleContact} ref={contactButton}>
                            <a>Контакты</a>
                          </LiSC>
                          <LiSC onClick={() => setBasket("basket")}>
                            <a>Корзина</a>
                          </LiSC>
                        </UlNavSC>

                        {/* <ContactOverlaySC onClick={handleContact}> */}
                        <ContactWrapperSC isShow={isShowContact}>
                          <TextSC style={{ height: "auto" }}>
                            <a href="tel:+79123084680">+7 (912) 308-46-80</a>
                            {/* +7 (912) 308-46-80 */}
                            <br />
                            <a href="mailto:artmova.ksenya@mail.ru">
                              artmova.ksenya@mail.ru
                            </a>
                            {/* artmova.ksenya@mail.ru */}
                          </TextSC>
                        </ContactWrapperSC>
                        {/* </ContactOverlaySC> */}
                      </NavSC>
                    </SidebarSC>
                  </SideBarOverlay>
                )}
              </>
            )}

            {!isTable && (
              <>
                <NavSC>
                  <UlNavSC>
                    <LiSC onClick={ScrollTo(0)}>
                      <a>О компании</a>
                    </LiSC>
                    <LiSC onClick={scrollToForm}>
                      <a>Обратная связь</a>
                    </LiSC>
                    <LiSC onClick={() => setRequisites("requisites")}>
                      <a>Покупателям</a>
                    </LiSC>
                    <LiSC onClick={scrollToCatalog}>
                      <a href="#Catalog">Каталог</a>
                    </LiSC>
                    <LiSC onClick={() => setBasket("basket")}>
                      Корзина
                      <SpanCountSC>{totalCount()}</SpanCountSC>
                    </LiSC>
                  </UlNavSC>
                </NavSC>
                <ContainerContantSC>
                  <a href="tel:+79123084680">+7 (912) 308-46-80</a>
                  <a href="mailto:artmova.ksenya@mail.ru">
                    artmova.ksenya@mail.ru
                  </a>
                </ContainerContantSC>
              </>
            )}
          </WrapperSC>
        </AppContainer>
      </HeaderContainerSC>
      <Basket
        isOpen={basket === "basket"}
        handleClose={handleBasket}
        className="basket"
      />
      <Requisites
        isOpen={requisites === "requisites"}
        handleClose={handleRequisites}
        className="req"
      />
    </HeaderSC>
  );
};

const {
  LiSC,
  NavSC,
  TextSC,
  MenuSC,
  UlNavSC,
  HeaderSC,
  WrapperSC,
  SidebarSC,
  ContactWrapperSC,
  ContactOverlaySC,
  WrapperTitleSC,
  ButtonMobileSC,
  HeaderContainerSC,
  ContainerContantSC,
  SideBarOverlay,
  SpanCountSC,
} = useHeaderStyle();

export default React.memo(Header);
