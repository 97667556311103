// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { useTablet } from "../../hooks/useMediaQuery";

type ModalState = "requisites" | "basket" | "productForm" | "";

export const useHeader = () => {
  const isTable = useTablet();
  const [IsOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);
  const [isShowContact, setIsShowContact] = useState(false);
  const [basket, setBasket] = useState<ModalState>("");
  const [requisites, setRequisites] = useState<ModalState>("");
  const menuButton = useRef<HTMLDivElement>(null);
  const menuMobile = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const contactButton = useRef<HTMLLIElement>(null);

  const handleBasket = (data: ModalState, callback?: () => void) => {
    setIsOpenSidebar(false);
    setIsShowContact(false);
    return () => {
      setBasket(data);
      if (callback) {
        callback();
      }
    };
  };

  const handleRequisites = (data: ModalState, callback?: () => void) => {
    setIsOpenSidebar(false);
    setIsShowContact(false);
    return () => {
      setRequisites(data);
      if (callback) {
        callback();
      }
    };
  };

  const scrollToCatalog = () => {
    const element = document.getElementById("Catalog");
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    setIsOpenSidebar((prev) => !prev);
    setIsShowContact(false);
  };

  const scrollToForm = () => {
    const elementt = document.getElementById("Form");

    elementt.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    setIsOpenSidebar((prev) => !prev);
    setIsShowContact(false);
  };

  const ScrollTo = (pixel: number) => {
    return () => {
      window.scrollTo({ top: pixel, behavior: "smooth" });
      document.body.style.overflowY = "scroll";
      setIsShowContact(false);
      setIsOpenSidebar(false);
    };
  };
  useEffect(() => {
    const onClick = (e) => {
      if (menuButton.current) {
        if (menuButton.current.contains(e.target)) {
          setIsOpenSidebar((prev) => !prev);
          setIsShowContact(false);
          return;
        }
      }
      if (menuMobile.current) {
        if (menuMobile.current.contains(e.target)) {
          return;
        }
      }
      if (
        !contactButton.current.contains(e.target) &&
        !contactRef.current.contains(e.target)
      ) {
        setIsShowContact(false);
      }
      setIsOpenSidebar(false);
    };

    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);

  const handleContact = () => {
    setIsShowContact((prev) => !prev);
  };
  return {
    isTable,
    ScrollTo,
    menuButton,
    menuMobile,
    contactRef,
    contactButton,
    handleContact,
    IsOpenSidebar,
    isShowContact,
    scrollToCatalog,
    scrollToForm,
    requisites,
    setRequisites,
    handleRequisites,
    basket,
    setBasket,
    handleBasket,
  };
};
