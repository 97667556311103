import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

export const InputSC = styled("input")<{ isError?: boolean }>`
  /* width: 85%; */
  /* width: 688px; */
  /* width: calc(100% - (460px)); */
  /* width: 668px; */
  width: 60%;

  /* min-width: 380px; */
  margin: 0 auto;
  height: 25px;
  font-weight: 600;
  /* font-size: 20px; */
  line-height: 27px;
  /* border-radius: 10px; */
  /* padding: 0 14px; */
  font-size: 15px;
  padding-bottom: 8px;

  /* border: ${({ theme, isError }) =>
    isError ? `2px solid ${theme.error}` : "none"}; */
  background: #edeef0;
  border: none;
  border-bottom: 0.661458px solid #000000;
  &::placeholder {
    opacity: 1;
    /* font-weight: 500; */
    font-size: 15px;
    line-height: 20px;
    color: #6f6f6f;

    /* color: ${({ theme, isError }) =>
      isError ? theme.error : theme.placeholder}; */
    ${mediaCSS(812)} {
      font-size: 14px;
      line-height: 19px;
      width: 312px;
      /* width: calc(100% - 60px); */
    }
  }
  ${mediaCSS(600)} {
    height: 25px;
    font-size: 15px;
    line-height: 20px;

    /* padding: 0 27px; */
  }
  ${mediaCSS(327)} {
    margin: 0 auto;
  }
`;

export const useInputStyled = () => ({
  InputSC,
});
