import styled from "styled-components";
import { InputSC } from "../../../../UI/InputUI/style";

const InputFullName = styled(InputSC)`
  margin-bottom: 27px;
`;

export const useFullNameStyle = () => ({
  InputFullName,
});
