import styled from "styled-components";
import { mediaCSS } from "../../../../theme/CSSPattern";
import { ButtonUI } from "../../../../UI/ButtonUI/style";

const CardWrapperSC = styled("div")`
  cursor: pointer;
  /* max-height: 278px; */
  height: 100%;
  /* width: 97%; */
  width: 100%;
  /* width: calc(100% - (480px * 2)); */
  /* max-width: 580px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  /* margin-bottom: 40px; */
  /* border-radius: 15px; */
  // overflow: hidden;
`;

const CardClickableSC = styled("div")`
  display: inline-flex;
  ${mediaCSS(996)} {
    margin-bottom: 4px;
    /* height: 315px !important; */
    min-height: 315px !important;
    flex-direction: column;
  }
`;

const TopWrapperSC = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;
const RightWrapperSC = styled("div")`
  /* padding: 11px 20px 24px 30px; */
  padding: 16px;
  max-width: 53%;
  ${mediaCSS(1300)} {
    padding: 11px 10px 24px 30px;
  }
  ${mediaCSS(996)} {
    max-width: 100%;
    padding: 9px 3px 16px 8px;
  }
`;

const PriceSC = styled("h4")`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 2px;
  font-family: sans-serif;

  ${mediaCSS(996)} {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
    width: 155px;
    text-align: right;
  }
`;
const NumberSC = styled("p")`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #8c8c8c;
  max-width: 70%;
  max-height: 50px;
  overflow: hidden;
  width: 100%;
  word-break: break-all;
  font-family: sans-serif;
  ${mediaCSS(1315)} {
    font-size: 14px;
  }
  ${mediaCSS(996)} {
    max-width: 100%;
    font-size: 14px;
    line-height: 19px;
  }
  ${mediaCSS(600)} {
    margin-bottom: 0;
  }
`;
const ImgSC = styled("img")`
  /* object-fit: cover; */
  object-fit: contain;
  max-width: 47%;
  width: 100%;
  height: 100%;
  max-height: 254px;
  ${mediaCSS(996)} {
    max-width: 100%;
    max-height: 115px;
  }
`;
const PlaceholderWrapperSC = styled("div")`
  position: relative;
  background: #c9c3b5;
  max-width: 47%;
  width: 100%;
  height: 100%;
`;
const PartNameSC = styled("h3")`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #0c0e20;
  /* min-height: 54px; */
  margin-bottom: 7px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* word-break: break-all; */
  font-family: sans-serif;
  -webkit-box-orient: vertical;
  ${mediaCSS(837)} {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 9px;
    min-height: 40px;
  }
`;

const DescriptionSC = styled("p")`
  font-weight: 500;
  /* font-size: calc(0.6rem + 1vw); */
  font-size: 14px;
  /* line-height: 20px; */
  /* font-size: 18px; */
  /* line-height: 25px; */
  color: #000000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-family: sans-serif;
  ${mediaCSS(996)} {
    margin-bottom: 16px;
    /* font-size: 10px; */
    line-height: 15px;
    /* min-height: 70px; */
  }
`;

const RowContainer = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 8px;
`;

const ButtonCardSC = styled(ButtonUI)`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  height: auto;
  padding: 8px 0;
`;

export const useProductCardStyle = () => ({
  ImgSC,
  PriceSC,
  NumberSC,
  PartNameSC,
  TopWrapperSC,
  DescriptionSC,
  CardWrapperSC,
  RightWrapperSC,
  PlaceholderWrapperSC,
  RowContainer,
  ButtonCardSC,
  CardClickableSC,
});
