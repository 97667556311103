import AboutCompany from "components/AboutCompany/AboutCompany";
import React from "react";
import { Controller, FormProvider } from "react-hook-form";
import { ButtonUI } from "../../../UI/ButtonUI/style";
import TitleUI from "../../../UI/TitleUI/TitleUI";
import EmailInput from "./EmailInput/EmailInput";
import FullNameInput from "./FullNameInput/FullNameInput";
import PhoneInput from "./ThemeInput/ThemeInput";
import { useFormFeedbackStyle } from "./style";
import TextArea from "./TextArea/TextArea";
import { useFormFeedback } from "./useFormFeedback";

const FormFeedback = () => {
  const {
    method,
    isError,
    isMobile,
    isTable,
    control,
    onSubmit,
    handleSubmit,
  } = useFormFeedback();
  return (
    <>
      <FormProvider {...method}>
        <FormSC onSubmit={handleSubmit(onSubmit)}>
          {/* {!isTable && <AboutCompany />} */}
          <FormTitleWrapperSC>
            <TitleUI
              text="Получите бесплатную консультацию"
              textAlign={isMobile ? "center" : "center"}
              styleComponents={{ TitleStyle: FormTitleSC }}
              style={{ textTransform: "none" }}
            />
          </FormTitleWrapperSC>
          {/* <TextArea /> */}
          <FullNameInput />
          <EmailInput />
          <PhoneInput />
          <ButtonUI type="submit">Отправить</ButtonUI>
          {isError && (
            <ErrorMessageSC>
              Что-то пошло не так, попробуйте снова...
            </ErrorMessageSC>
          )}
        </FormSC>
      </FormProvider>
    </>
  );
};

const {
  FormSC,
  ButtonSC,
  FormTitleSC,
  RequisitesSC,
  DescriptionSC,
  ErrorMessageSC,
  RequisitesTitleSC,
  FormTitleWrapperSC,
  RequisitesTitleWrapperSC,
} = useFormFeedbackStyle();

export default React.memo(FormFeedback);
