import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useBasketStyles } from "./useBasketStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  getBasket,
  removeAllProducts,
} from "../../../store/features/basketSlice";
import BasketCard from "./BasketCard/BasketCard";
import { ButtonUI } from "UI/ButtonUI/style";
import BasketForm from "./BasketForm/BasketForm";

type ModalState = "requisites" | "basket" | "productForm" | "";

interface IModalUIProps {
  isOpen: boolean;
  handleClose: (arg: ModalState) => () => void;
  className?: string;
}

const Basket = ({ isOpen, className = "", handleClose }: IModalUIProps) => {
  const basket = useSelector(getBasket);
  const dispatch = useDispatch();
  const [state, setState] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {}, [isOpen, close]);

  const onKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose("")();
    }
  };

  const onClickOverlay = (e: React.MouseEvent<HTMLElement>) => {
    handleClose("");

    if ((e.target as HTMLElement).classList.contains("basket")) {
      handleClose("")();
    }
  };

  const totalPrice = () => {
    let countPrice = 0;
    for (let i in basket) {
      countPrice += Number(basket[i].price) * basket[i].quantity;
    }
    return countPrice;
  };

  const handleNextStep = () => {
    setState(!state);
  };

  useEffect(() => {
    if (!isOpen) {
      setState(false);
    }
    if (success) {
      handleClose("")();
      dispatch(removeAllProducts());
      setSuccess(false);
    }
    if (isOpen) {
      document.body.style.overflowY = "hidden";
      window.addEventListener("keydown", onKey);
    } else {
      document.body.style.overflowY = "scroll";
      window.removeEventListener("keydown", onKey);
    }
    return () => {
      window.removeEventListener("keydown", onKey);
    };
  }, [isOpen, success]);

  return isOpen ? (
    <>
      <ModalContainerSC>
        <MainContainerSC className={className}>
          {!state && (
            <>
              <TopContainer>
                <div>
                  <TitleSC>Корзина</TitleSC>
                  {basket.length !== 0 && (
                    <DeleteButtonSC
                      onClick={() => dispatch(removeAllProducts())}>
                      Очистить корзину
                    </DeleteButtonSC>
                  )}
                </div>
                <ReplyIconSC onClick={handleClose("")} src="ReplyIcon.svg" />
              </TopContainer>
              <ContainerSC>
                <BasketListSC>
                  {basket.map((product) => (
                    <BasketCard
                      key={product.vendor_code}
                      price={product.price}
                      title={product.title}
                      image={product.image}
                      vendor_code={product.vendor_code}
                      quantity={product.quantity}
                    />
                  ))}
                </BasketListSC>
                {basket.length === 0 && (
                  <PlaceholderSC>Вы ничего не добавили!</PlaceholderSC>
                )}
              </ContainerSC>
              {basket.length !== 0 && (
                <TotalSC>
                  <div>
                    <h3>Итого:</h3>
                    <h3>{totalPrice()} ₽</h3>
                  </div>
                  <ButtonUI onClick={handleNextStep}>Заказать</ButtonUI>
                </TotalSC>
              )}
            </>
          )}
          {state && (
            <BasketForm
              returnBasket={(value: boolean) => setState(value)}
              isSuccessForm={(value: boolean) => setSuccess(value)}
            />
          )}
        </MainContainerSC>
      </ModalContainerSC>
      {createPortal(
        <ModalBackdoorSC onClick={handleClose("")} />,
        document.querySelector("#root") as HTMLElement,
      )}
    </>
  ) : null;
};

const {
  ContainerSC,
  ModalBackdoorSC,
  ModalContainerSC,
  DeleteButtonSC,
  TitleSC,
  BasketListSC,
  TopContainer,
  PlaceholderSC,
  MainContainerSC,
  TotalSC,
  ReplyIconSC,
} = useBasketStyles();

export default React.memo(Basket);
