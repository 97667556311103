import styled from "styled-components";
import { mediaCSS } from "../../../theme/CSSPattern";
import { TitleSC } from "../../../UI/TitleUI/style";

const FormSC = styled("form")`
  /* width: 100%; */
  /* width: calc(100% - (192px * 2)); */
  /* width: 70%; */
  margin: 0 auto;
  padding: 64px 0;
  margin-bottom: 140px;
  background: #edeef0;
  //   position: sticky;
  display: flex;
  /* width: calc(100% - 192px * 2); */
  width: 86%;
  flex-direction: column;
  //   top: 0;
  /* ${mediaCSS(1200)} {
    max-width: 295px;
  } */
  ${mediaCSS(887)} {
    /* max-width: 333px; */
    width: 92%;
    margin-bottom: 40px;
    padding: 30px 0;
    max-width: 100%;
    /* padding: 0px 15px 0 15px; */
  }
`;
const FormTitleWrapperSC = styled("div")`
  /* margin-bottom: 35px;
   */
  margin: 0 auto 52px;

  ${mediaCSS(600)} {
    margin-bottom: 19px;
    max-width: 320px;
    text-align: center;
    margin: 0 auto 31px;
  }
`;

//   height: 60px;
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 27px;

const ButtonSC = styled("button")`
  cursor: pointer;
  background: ${({ theme }) => theme.buttonColor};
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-bottom: 48px;
  height: 60px;
  ${mediaCSS(600)} {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const RequisitesSC = styled("div")`
  background: linear-gradient(
    180deg,
    #ffffff 64.36%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 15px;

  padding: 39px 27px 51px 36px;
  ${mediaCSS(600)} {
    padding: 16px 40px 25px 22px;
  }
`;

const RequisitesTitleWrapperSC = styled("div")`
  margin-bottom: 18px;
  ${mediaCSS(600)} {
    margin-bottom: 11px;
  }
`;

const DescriptionSC = styled("p")`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #8c8c8c;
  ${mediaCSS(600)} {
    font-size: 14px;
    line-height: 19px;
  }
`;

const FormTitleSC = styled(TitleSC)`
  color: #000000;

  ${mediaCSS(600)} {
    font-size: 20px;
    line-height: 24px;
  }
`;
const RequisitesTitleSC = styled(TitleSC)`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  text-decoration-line: line-through;
  color: #8c8c8c;
`;
const ErrorMessageSC = styled("p")`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #f04a4a;
  margin-bottom: 31px;
  padding-left: 17px;
`;

export const useFormFeedbackStyle = () => ({
  FormSC,
  ButtonSC,
  FormTitleSC,
  RequisitesSC,
  DescriptionSC,
  ErrorMessageSC,
  RequisitesTitleSC,
  FormTitleWrapperSC,
  RequisitesTitleWrapperSC,
});
