import styled from "styled-components";
import { mediaCSS } from "../../../../../theme/CSSPattern";
import { TitleSC } from "../../../../../UI/TitleUI/style";

const FormSC = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 16px;
`;

const FormTitleWrapperSC = styled("div")`
  display: flex;
  justify-content: center;
`;

const FormWrapperSC = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`;

const ReplyIconSC = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
`;

const ButtonSC = styled("button")`
  cursor: pointer;
  background: ${({ theme }) => theme.buttonColor};
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-bottom: 48px;
  height: 60px;
  ${mediaCSS(600)} {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const RequisitesSC = styled("div")`
  background: linear-gradient(
    180deg,
    #ffffff 64.36%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 15px;

  padding: 39px 27px 51px 36px;
  ${mediaCSS(600)} {
    padding: 16px 40px 25px 22px;
  }
`;

const RequisitesTitleWrapperSC = styled("div")`
  margin-bottom: 18px;
  ${mediaCSS(600)} {
    margin-bottom: 11px;
  }
`;

const DescriptionSC = styled("p")`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #8c8c8c;
  ${mediaCSS(600)} {
    font-size: 14px;
    line-height: 19px;
  }
`;

const FormTitleSC = styled(TitleSC)`
  color: white;
  font-size: 24px;
  max-width: 60%;
  ${mediaCSS(600)} {
    font-size: 20px;
    line-height: 22px;
  }
`;
const RequisitesTitleSC = styled(TitleSC)`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  text-decoration-line: line-through;
  color: #8c8c8c;
`;
const ErrorMessageSC = styled("p")`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #f04a4a;
  text-align: center;
`;

const ModalContainerSC = styled("div")`
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: modalin 0.7s ease-in-out;
  @keyframes modalin {
    from {
      transform: translate(150%, -50%);
    }
    to {
      transform: translate(-50%, -50%);
    }
  }
`;

const MainContainerSC = styled.div`
  z-index: 5;
  position: relative;
  width: 50%;
  max-width: 48rem;
  height: 41rem;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  padding: 1.16rem;

  display: flex;
  flex-direction: column;
  gap: 1.16rem;

  @media (max-width: 1174px) {
    width: 90%;
    max-width: none;
  }
`;

const ModalBackdoorSC = styled("div")`
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  animation: modalBackdoorIn 0.7s ease-in-out;
  @keyframes modalBackdoorIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
`;

export const useProductFormStyle = () => ({
  FormSC,
  ButtonSC,
  FormTitleSC,
  RequisitesSC,
  DescriptionSC,
  ErrorMessageSC,
  FormWrapperSC,
  RequisitesTitleSC,
  FormTitleWrapperSC,
  RequisitesTitleWrapperSC,
  ReplyIconSC,
  ModalContainerSC,
  MainContainerSC,
  ModalBackdoorSC,
});
