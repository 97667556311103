import styled from "styled-components";
import { InputSC } from "../../../../../UI/InputUI/style";

const InputFullName = styled(InputSC)`
  padding: 24px;
`;

export const useFullNameStyle = () => ({
  InputFullName,
});
