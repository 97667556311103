import React from "react";
import { UseNotFoundIconStyle } from "./style";

const NotFoundIcon = () => {
  return (
    <IconWrapperSC>
      <svg
        width="157"
        height="128"
        viewBox="0 0 157 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M42.4601 1.61041H52.811C55.6694 1.61041 57.9864 3.92756 57.9864 6.78585V9.72958C57.9864 11.9436 59.4723 13.8671 61.5171 14.7161C63.5629 15.5653 65.9447 15.2417 67.5108 13.6755L69.5928 11.5934C71.6138 9.5722 74.8909 9.5722 76.9119 11.5934L84.231 18.9125C86.2526 20.9337 86.252 24.2105 84.231 26.2317L82.1495 28.3136C80.5829 29.8798 80.2594 32.2618 81.1087 34.3075C81.9575 36.3524 83.8812 37.8384 86.0957 37.8384H89.039C91.8974 37.8384 94.2144 40.1555 94.2144 43.0139V53.3647C94.2144 56.2231 91.8974 58.5402 89.039 58.5402H86.0952C83.8812 58.5402 81.958 60.0261 81.1087 62.0709C80.2594 64.1167 80.5829 66.4984 82.1495 68.0645L84.231 70.1466C86.2526 72.1676 86.2526 75.4447 84.231 77.4657L76.9119 84.7848C74.8909 86.8063 71.6138 86.8063 69.5928 84.7848L67.5108 82.7032C65.9447 81.1366 63.5629 80.8132 61.5171 81.6625C59.4723 82.5118 57.9864 84.4349 57.9864 86.649V89.5928C57.9864 92.4512 55.6694 94.7682 52.811 94.7682H42.4601C39.6017 94.7682 37.2847 92.4512 37.2847 89.5928V86.6495C37.2847 84.4349 35.7987 82.5112 33.7537 81.6625C31.708 80.8132 29.3261 81.1366 27.7598 82.7032L25.6778 84.7848C23.6567 86.8063 20.3798 86.8063 18.3587 84.7848L11.0395 77.4657C9.01837 75.4447 9.01837 72.1676 11.0395 70.1466L13.1218 68.0645C14.6879 66.4984 15.0116 64.1167 14.1623 62.0709C13.3134 60.0261 11.3899 58.5402 9.17581 58.5402H6.23207C3.37379 58.5402 1.05664 56.2231 1.05664 53.3647V43.0139C1.05664 40.1555 3.37379 37.8384 6.23207 37.8384H9.17571C11.3898 37.8384 13.3134 36.3525 14.1623 34.3076C15.0117 32.2619 14.688 29.8801 13.1218 28.3139L11.0396 26.2317C9.01842 24.2105 9.01842 20.9337 11.0396 18.9126L18.3587 11.5934C20.3799 9.57225 23.6568 9.57225 25.6779 11.5934L27.7601 13.6755C29.3263 15.2418 31.7081 15.5655 33.7538 14.7161C35.7987 13.8671 37.2847 11.9436 37.2847 9.72943V6.78585C37.2847 3.92756 39.6017 1.61041 42.4601 1.61041Z"
          stroke="#938E83"
          strokeWidth="2"
        />
        <path
          d="M57.9859 48.1893C57.9859 53.9061 53.3518 58.5401 47.635 58.5401C41.9183 58.5401 37.2842 53.9061 37.2842 48.1893C37.2842 42.4725 41.9183 37.8384 47.635 37.8384C53.3518 37.8384 57.9859 42.4725 57.9859 48.1893Z"
          stroke="#938E83"
          strokeWidth="2"
        />
        <path
          d="M117.3 33.893L125.063 35.1742C127.206 35.528 128.657 37.5526 128.303 39.6961L127.939 41.9037C127.665 43.5642 128.541 45.1906 129.97 46.0803C131.399 46.9705 133.225 47.0226 134.593 46.0419L136.413 44.7381C138.178 43.4725 140.636 43.8782 141.901 45.6441L146.484 52.039C147.75 53.805 147.344 56.2624 145.578 57.528L143.76 58.8316C142.391 59.8123 141.854 61.5586 142.237 63.1978C142.621 64.8365 143.879 66.1891 145.54 66.4632L147.747 66.8275C149.891 67.1813 151.342 69.2058 150.988 71.3494L149.707 79.112C149.353 81.2556 147.329 82.7064 145.185 82.3526L142.977 81.9882C141.317 81.7142 139.691 82.5905 138.801 84.0188C137.91 85.448 137.858 87.2742 138.839 88.6426L140.143 90.4617C141.408 92.2275 141.003 94.6851 139.237 95.9506L132.842 100.534C131.076 101.799 128.618 101.394 127.353 99.6275L126.049 97.8088C125.068 96.4401 123.322 95.9027 121.683 96.2863C120.044 96.6701 118.692 97.9285 118.418 99.5889L118.054 101.797C117.7 103.94 115.675 105.391 113.532 105.037L105.769 103.756C103.625 103.402 102.175 101.378 102.528 99.2341L102.893 97.0268C103.167 95.366 102.291 93.7394 100.862 92.8498C99.433 91.9596 97.6066 91.9074 96.2381 92.8884L94.4191 94.1917C92.6531 95.4575 90.1956 95.0519 88.9301 93.2857L84.3471 86.8908C83.0816 85.125 83.4872 82.6674 85.2531 81.4019L87.0724 80.0982C88.4408 79.1176 88.9783 77.3715 88.5946 75.7321C88.2111 74.0936 86.9525 72.7411 85.2921 72.4671L83.0845 72.1027C80.9409 71.7489 79.49 69.7244 79.8438 67.5808L81.125 59.8183C81.4789 57.6746 83.5034 56.2238 85.6469 56.5776L87.8545 56.942C89.5149 57.216 91.1414 56.3397 92.0312 54.9112C92.9214 53.4823 92.9735 51.656 91.9928 50.2875L90.689 48.4683C89.4234 46.7023 89.829 44.2449 91.595 42.9793L97.9899 38.3963C99.7558 37.1308 102.213 37.5364 103.479 39.3023L104.783 41.1215C105.763 42.49 107.51 43.0276 109.149 42.6438C110.787 42.2603 112.14 41.0016 112.414 39.3411L112.778 37.1336C113.132 34.9901 115.157 33.5392 117.3 33.893Z"
          fill="#C9C3B5"
          stroke="#938E83"
          strokeWidth="2"
        />
        <path
          d="M123.177 70.7462C122.47 75.0334 118.421 77.9351 114.134 77.2275C109.846 76.5199 106.945 72.471 107.652 68.1837C108.36 63.8964 112.409 60.9948 116.696 61.7024C120.983 62.41 123.885 66.4589 123.177 70.7462Z"
          fill="#C9C3B5"
          stroke="#938E83"
          strokeWidth="2"
        />
        <path
          d="M67.8294 64.1264L74.2984 65.1941C76.0849 65.489 77.294 67.1762 76.9991 68.9625L76.6954 70.8023C76.4671 72.186 77.1973 73.5415 78.3877 74.2829C79.5787 75.0248 81.1006 75.0682 82.2409 74.2509L83.7569 73.1644C85.2285 72.1097 87.2766 72.4477 88.3312 73.9194L92.1505 79.2487C93.2054 80.7204 92.8671 82.7683 91.3955 83.823L89.8798 84.9094C88.7391 85.7267 88.2913 87.182 88.611 88.5481C88.9305 89.9137 89.9795 91.0409 91.3636 91.2693L93.2031 91.573C94.9895 91.8678 96.1986 93.5549 95.9037 95.3414L94.836 101.81C94.5411 103.597 92.854 104.806 91.0676 104.511L89.2278 104.207C87.844 103.979 86.4888 104.709 85.7471 105.9C85.0053 107.091 84.9617 108.613 85.7793 109.753L86.8654 111.269C87.9204 112.74 87.5823 114.789 86.1104 115.843L80.7812 119.662C79.3095 120.717 77.2614 120.379 76.2069 118.907L75.1204 117.392C74.3032 116.251 72.848 115.803 71.4818 116.123C70.1162 116.443 68.9892 117.492 68.7608 118.875L68.4572 120.715C68.1623 122.501 66.4752 123.711 64.6888 123.416L58.2197 122.348C56.4333 122.053 55.2242 120.366 55.519 118.58L55.8226 116.74C56.0511 115.356 55.3208 114 54.1303 113.259C52.9394 112.517 51.4174 112.474 50.2769 113.291L48.761 114.377C47.2893 115.432 45.2413 115.094 44.1867 113.622L40.3673 108.293C39.3127 106.822 39.6507 104.773 41.1223 103.719L42.6385 102.632C43.7788 101.815 44.2268 100.36 43.9071 98.9938C43.5874 97.6282 42.5386 96.5012 41.1548 96.2728L39.3151 95.9691C37.5287 95.6743 36.3196 93.9872 36.6144 92.2007L37.6822 85.7317C37.977 83.9452 39.6642 82.7362 41.4506 83.031L43.2903 83.3347C44.674 83.563 46.0295 82.8328 46.771 81.6423C47.5129 80.4515 47.5563 78.9295 46.739 77.7891L45.6524 76.273C44.5977 74.8013 44.9358 72.7533 46.4074 71.6987L51.7368 67.8793C53.2084 66.8247 55.2564 67.1627 56.3111 68.6343L57.3976 70.1504C58.2149 71.2909 59.6701 71.7389 61.0362 71.4191C62.4018 71.0994 63.5289 70.0505 63.7573 68.6667L64.061 66.827C64.3558 65.0407 66.0429 63.8315 67.8294 64.1264Z"
          fill="#C9C3B5"
          stroke="#938E83"
          strokeWidth="2"
        />
        <path
          d="M72.7268 94.8387C72.1371 98.4115 68.7629 100.83 65.19 100.24C61.6172 99.6503 59.199 96.276 59.7887 92.7032C60.3784 89.1303 63.7526 86.7121 67.3255 87.3018C70.8984 87.8916 73.3165 91.2658 72.7268 94.8387Z"
          fill="#C9C3B5"
          stroke="#938E83"
          strokeWidth="2"
        />
      </svg>
    </IconWrapperSC>
  );
};

const { IconWrapperSC } = UseNotFoundIconStyle();

export default React.memo(NotFoundIcon);
