import React from "react";
import { IProductCardsProps } from "../../../../types/types";
import NotFoundIcon from "../../../BottomBlock/ProductList/ProductCard/NotFoundIcon/NotFoundIcon";
import { useBasketCardStyles } from "./useBasketCardStyles";
import DeleteIcon from "./DeleteIcon";
import { useDispatch } from "react-redux";
import {
  decreaseQuantity,
  increaseQuantity,
  removeProduct,
  setQuantity,
} from "store/features/basketSlice";

const BasketCard = ({
  price,
  title,
  image,
  vendor_code,
  style,
  quantity,
}: IProductCardsProps) => {
  const dispatch = useDispatch();

  return (
    <CardWrapperSC style={style}>
      {image ? (
        <ImgSC src={image} />
      ) : (
        <PlaceholderWrapperSC>
          <NotFoundIcon />
        </PlaceholderWrapperSC>
      )}
      <RightWrapperSC>
        <TopWrapperSC>
          <div>
            <PartNameSC>{title}</PartNameSC>
            <NumberSC>{vendor_code}</NumberSC>
          </div>
          <PriceSC>{price} ₽</PriceSC>
        </TopWrapperSC>
        <ActionWrapperSC>
          <CountContainerSC>
            <SplitButtonSC
              onClick={() => dispatch(increaseQuantity(vendor_code))}>
              +
            </SplitButtonSC>
            <CountSC
              type={"number"}
              value={quantity}
              onChange={(e) =>
                dispatch(setQuantity([Number(e.target.value), vendor_code]))
              }
              onBlur={(e) => {
                e.target.value === "0" && dispatch(removeProduct(vendor_code));
              }}
            />
            <SplitButtonSC
              onClick={() => dispatch(decreaseQuantity(vendor_code))}>
              -
            </SplitButtonSC>
          </CountContainerSC>
          <ButtonSC onClick={() => dispatch(removeProduct(vendor_code))}>
            <DeleteIcon />
          </ButtonSC>
        </ActionWrapperSC>
      </RightWrapperSC>
    </CardWrapperSC>
  );
};

const {
  ImgSC,
  PriceSC,
  NumberSC,
  PartNameSC,
  TopWrapperSC,
  CardWrapperSC,
  RightWrapperSC,
  PlaceholderWrapperSC,
  ActionWrapperSC,
  ButtonSC,
  CountContainerSC,
  SplitButtonSC,
  CountSC,
} = useBasketCardStyles();

export default React.memo(BasketCard);
