import styled from "styled-components";
import { mediaCSS } from "theme/CSSPattern";

const WrapperSC = styled("main")``;

const Background = styled("div")`
  /* background-image: url("backgroundMainPageDekstop1920.jpg");
  background-size: 100% 100%;
  background-position: top right;
  height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  @media (max-width: 1060px) {
    background-image: url("backgroundMainPageTablet.jpg");
  }
  @media (max-width: 500px) {
    background-image: url("backgroundMainPageMobile.jpg");
    height: 450px;
  } */
  max-width: 1920px;
  width: 100%;

  height: 100vh;
  max-height: 1036px;

  margin: 0 auto;

  background-image: url("backgroundMainPageDekstop1920.jpg");
  background-position: top left;
  background-size: 1920px 1036px;
  background-repeat: no-repeat;

  margin-bottom: 64px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 1401px) {
    background-image: url("backgroundMainPageDekstop1041.jpg");
    background-size: 1401px 100%;
  }

  @media (max-width: 1041px) {
    background-size: 1041px 626px;
    max-height: 626px;
  }

  @media (max-width: 604px) {
    background-image: url("backgroundMainPageMobile.jpg");
    background-size: 604px 350px;
    max-height: 350px;
    margin-bottom: 25px;
  }
`;

export const useMainStyle = () => ({
  WrapperSC,
  Background,
});
