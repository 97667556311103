import React from "react";
import { useArrowTopStyle } from "./style";
import { useArrowTop } from "./useArrowTop";

const ArrowTop = () => {
  const { scrollTop, isVisible } = useArrowTop();
  return (
    <>
      {isVisible && (
        <WrapperSC onClick={scrollTop}>
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="28" fill="white" />
            <mask
              id="mask0_183_1888"
              // style="mask-type:alpha"
              maskUnits="userSpaceOnUse"
              x="12"
              y="12"
              width="32"
              height="32">
              <rect
                x="12"
                y="44"
                width="32"
                height="32"
                transform="rotate(-90 12 44)"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_183_1888)">
              <path
                d="M35.0333 26.2667C34.7667 26.5334 34.4444 26.6611 34.0667 26.65C33.6889 26.6389 33.3667 26.5 33.1 26.2334L29.3333 22.4667V37.3334C29.3333 37.7111 29.2055 38.0278 28.95 38.2834C28.6944 38.5389 28.3778 38.6667 28 38.6667C27.6222 38.6667 27.3055 38.5389 27.05 38.2834C26.7944 38.0278 26.6667 37.7111 26.6667 37.3334V22.4667L22.8667 26.2667C22.6 26.5334 22.2833 26.6667 21.9167 26.6667C21.55 26.6667 21.2333 26.5334 20.9667 26.2667C20.7 26 20.5667 25.6834 20.5667 25.3167C20.5667 24.95 20.7 24.6334 20.9667 24.3667L27.0667 18.2667C27.2 18.1334 27.3444 18.0389 27.5 17.9834C27.6555 17.9278 27.8222 17.9 28 17.9C28.1778 17.9 28.3444 17.9278 28.5 17.9834C28.6555 18.0389 28.8 18.1334 28.9333 18.2667L35.0667 24.4C35.3111 24.6445 35.4333 24.95 35.4333 25.3167C35.4333 25.6834 35.3 26 35.0333 26.2667Z"
                fill="#6F6F6F"
              />
            </g>
          </svg>
        </WrapperSC>
      )}
    </>
  );
};

const { WrapperSC } = useArrowTopStyle();

export default React.memo(ArrowTop);
