import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

const WrapperSC = styled("section")`
  /* padding: 96px 0 0 31px; */
  ${mediaCSS(996)} {
    padding: 21px 0px 31px 0px;
    width: 92%;
    margin: 0 auto;
  }
`;
const ContentWrapperSC = styled("div")`
  display: flex;
  justify-content: space-between;

  overflow: hidden;
  ${mediaCSS(996)} {
    flex-direction: column-reverse;
  }
`;

export const useBottomBlockStyle = () => ({
  WrapperSC,
  ContentWrapperSC,
});
