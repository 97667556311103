import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import TitleUI from "UI/TitleUI/TitleUI";
import { useBasketFormFeedback } from "./useBasketFormFeedback";
import FullNameInput from "./FullNameInput/FullNameInput";
import EmailInput from "./EmailInput/EmailInput";
import { ButtonUI } from "../../../../UI/ButtonUI/style";
import { useBasketFormStyle } from "./style";

const BasketForm = ({ returnBasket, isSuccessForm }) => {
  const {
    method,
    isError,
    isSuccess,
    isLoading,
    isMobile,
    isTable,
    control,
    onSubmit,
    handleSubmit,
  } = useBasketFormFeedback();

  const handleReturnBasket = () => {
    returnBasket(false);
  };

  useEffect(() => {
    if (isSuccess === true) {
      isSuccessForm(true);
    }
  }, [isSuccess]);

  const handleCloseForm = () => {
    isSuccessForm(true);
  };

  return (
    <>
      <FormProvider {...method}>
        <FormSC onSubmit={handleCloseForm}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}>
            <ReplyIconSC onClick={handleReturnBasket} src="ExitButton.svg" />
          </div>
          <FormTitleWrapperSC>
            <TitleUI
              text="Отправьте заявку и мы рассчитаем выгодные условия и сроки поставки"
              textAlign={isMobile ? "center" : "center"}
              styleComponents={{ TitleStyle: FormTitleSC }}
              style={{ textTransform: "none" }}
            />
          </FormTitleWrapperSC>
          <FormWrapperSC>
            <div>
              <FullNameInput />
              <EmailInput />
            </div>

            <ButtonUI type="submit">Отправить</ButtonUI>
            {isError && (
              <ErrorMessageSC>
                Что-то пошло не так, попробуйте снова...
              </ErrorMessageSC>
            )}
          </FormWrapperSC>
        </FormSC>
      </FormProvider>
    </>
  );
};

const {
  FormSC,
  ButtonSC,
  FormTitleSC,
  RequisitesSC,
  DescriptionSC,
  ErrorMessageSC,
  RequisitesTitleSC,
  FormTitleWrapperSC,
  FormWrapperSC,
  RequisitesTitleWrapperSC,
  ReplyIconSC,
} = useBasketFormStyle();

export default React.memo(BasketForm);
