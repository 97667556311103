import { useEffect, useRef, useState } from "react";
import { useTablet } from "../../../../hooks/useMediaQuery";

export const useProductCard = () => {
  const [isVisibleFull, setIsVIsibleFull] = useState(false);
  const isTable = useTablet();
  useEffect(() => {}, []);

  const handleClickOut = () => {
    setIsVIsibleFull(false);
    document.body.style.overflow = "scroll";
  };
  const handleShowFull = () => {
    setIsVIsibleFull((prev) => !prev);
    if (isTable) {
      console.log(isVisibleFull);
      if (!isVisibleFull) {
        document.body.style.overflow = "hidden";
        return;
      } else {
        document.body.style.overflow = "scroll";
      }
    }
  };

  return {
    isTable,
    isVisibleFull,
    handleShowFull,
    handleClickOut,
  };
};
