const DeleteIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.70038 19.4951C3.13932 19.4951 2.66012 19.2964 2.26277 18.8991C1.8654 18.5017 1.66672 18.0225 1.66672 17.4615V3.18265H0.541718V1.49517H5.60419V0.5H12.3542V1.49517H17.4167V3.18265H16.2917V17.4615C16.2917 18.0297 16.0948 18.5107 15.701 18.9045C15.3073 19.2982 14.8263 19.4951 14.258 19.4951H3.70038ZM14.6042 3.18265H3.35419V17.4615C3.35419 17.5624 3.38665 17.6454 3.45156 17.7103C3.51647 17.7752 3.59941 17.8076 3.70038 17.8076H14.258C14.3445 17.8076 14.4239 17.7716 14.496 17.6995C14.5681 17.6273 14.6042 17.548 14.6042 17.4615V3.18265ZM6.05855 15.5576H7.74602V5.43265H6.05855V15.5576ZM10.2124 15.5576H11.8998V5.43265H10.2124V15.5576Z"
        fill="white"
      />
    </svg>
  );
};

export default DeleteIcon;
