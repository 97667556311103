import React from "react";
import { Controller } from "react-hook-form";
import InputUI from "../../../../UI/InputUI/InputUI";
import { useThemeInputStyle } from "./style";
import { usePhoneInput } from "./useThemeInput";

const TextArea = () => {
  const { control } = usePhoneInput();
  return (
    <Controller
      name="theme"
      control={control}
      defaultValue={""}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <InputUI
            value={value}
            onChange={onChange}
            error={!!error}
            inputProps={{
              inputMode: "text",
              type: "text",
              placeholder: "Тема запроса",
            }}
            styleComponents={{
              InputStyle: InputTheme,
            }}
          />
        );
      }}
    />
  );
};

const { InputTheme } = useThemeInputStyle();

export default React.memo(TextArea);
